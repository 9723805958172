.rowEnabled {
  cursor: pointer !important;
  background-color: #c0c0c0;
}

.rowEnabled:hover {
  background-color: #c0c0c0 !important;
}

.rowDisabled {
  cursor: default !important;
}

.rowDisabled:hover {
  background-color: #c0c0c0 !important;
}
